import React, { useState } from 'react';
import './App.css';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState('');
  const [showRequestForm, setShowRequestForm] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    name: '',
    network: '',
    walletAddress: ''
  });

  const handlePasswordSubmit = () => {
    if (password === 'IAI') { // default password
      setIsAuthenticated(true);
    } else {
      alert('Incorrect password');
    }
  };

  const handleRequestSubmit = (e) => {
    e.preventDefault();
    alert('Coming Soon');
  };

  return (
    <div className="App container mt-5">
      {!isAuthenticated ? (
        <div className="auth-form text-center">
          <h2 className="mb-4">Please enter password to access</h2>
          <input
            type="password"
            className="form-control mb-3"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button className="btn btn-primary" onClick={handlePasswordSubmit}>Submit</button>
        </div>
      ) : (
        <div>
          <h1 className="mb-4 text-center">Token Information</h1>
          <div className="text-center mb-4">
            {/* <img src="https://ipfs.mintgarden.io/ipfs/bafybeidtht73xukt7ln36dfrufrqgpo5knilmyr47n6os636anhq6xpeha/Moneda.png" alt="Token" className="mb-3" /> */}
            <img src="https://ipfs.mintgarden.io/ipfs/bafybeigw3gtlasqhggm3jnce6ezvjic2i5r2u3mbttbrn4vps6tc555el4/Banner%20IAI.png" alt="Token" className="image" />
            <div>
              <h2>Chia Network: <span className="badge badge-success">IAI</span></h2>
              <h2>Ethereum Network: <span className="badge badge-success">IJAI</span></h2>
              <p class="highlight-text">
                  Inspired by an upcoming science fiction series, the IAI token heralds the dawn of a new era. This innovative token is designed to reward readers for their curiosity and engagement with the stories. By immersing themselves in the new series, readers can earn IAI tokens, celebrating their journey through the imaginative landscapes crafted within the novel.
              </p>
              <p class="highlight-text">
                  In addition, the novel will feature hidden clues and tips, enhancing the reading experience. These concealed elements will add layers of intrigue and excitement, encouraging readers to delve deeply into the narrative and engage with the story in an interactive and rewarding way.
              </p>            
            </div>
          </div>
          <div className="text-center">
            <button className="btn btn-warning mb-4" onClick={() => setShowRequestForm(true)}>Request Price</button>
          </div>
          {showRequestForm && (
            <form className="request-form" onSubmit={handleRequestSubmit}>
              <div className="form-group">
                <label>Email:</label>
                <input
                  type="email"
                  className="form-control"
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                />
              </div>
              <div className="form-group">
                <label>Name:</label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                />
              </div>
              <div className="form-group">
                <label>Network:</label>
                <select
                  className="form-control"
                  value={formData.network}
                  onChange={(e) => setFormData({ ...formData, network: e.target.value })}
                >
                  <option value="Chia Network">Chia Network</option>
                  <option value="Ethereum Network">Ethereum Network</option>
                </select>
              </div>
              <div className="form-group">
                <label>Destination Wallet Address:</label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.walletAddress}
                  onChange={(e) => setFormData({ ...formData, walletAddress: e.target.value })}
                />
              </div>
              <button type="submit" className="btn btn-success">Process Request</button>
            </form>
          )}
        </div>
      )}
    </div>
  );
};

export default App;
